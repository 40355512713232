import React, { Component } from 'react'
import NewsItem from './NewsItem'

export class News extends Component {
    articles = [
        {
          "source": { "id": "reuters", "name": "Reuters" },
          "author": null,
          "title": "Biden says United States would come to Taiwan's defense - Reuters",
          "description": "The United States would come to Taiwan's defense and has a commitment to defend the island China claims as its own territory, U.S. President Joe Biden said on Thursday, comments that appeared to be a break with official policy.",
          "url": "https://www.reuters.com/world/asia-pacific/biden-says-united-states-would-come-taiwans-defense-2021-10-22/",
          "urlToImage": "https://www.reuters.com/resizer/bXuLKpgDqgYFGkfyUWtcghYCFzY=/1200x628/smart/filters:quality(80)/cloudfront-us-east-2.images.arcpublishing.com/reuters/HMZQ3ATJMBK2ROFP6J3QVNWKXI.jpg",
          "publishedAt": "2021-10-22T02:07:00Z",
          "content": "U.S. President Joe Biden speaks briefly with reporters after participating in a ceremony for state and national Teachers of the Year at the White House in Washington, U.S. October 18, 2021. REUTERS/J… [+2842 chars]"
        },
        {
          "source": { "id": "politico", "name": "Politico" },
          "author": "Myah Ward, Sam Stein",
          "title": "Biden uses town hall to name-check Manchin and Sinema on agenda hold-ups - POLITICO",
          "description": "The president has been working to bring together a divided Democratic Party on social spending, but in the CNN event he spotlighted the two main holdouts.",
          "url": "https://www.politico.com/news/2021/10/21/biden-town-hall-manchin-and-sinema-agenda-516685",
          "urlToImage": "https://cf-images.us-east-1.prod.boltdns.net/v1/static/1155968404/cc7f07b2-7a7a-42b7-8a86-8216bde77013/ea01b351-139f-437a-98cb-188c428f5961/1280x720/match/image.jpg",
          "publishedAt": "2021-10-22T02:02:57Z",
          "content": "Joes not a bad guy. Hes a friend, he said of Manchin, while talking about the difficulty in coming to an agreement with him on expanding Medicare to cover dental, vision and hearing benefits. I think… [+3568 chars]"
        },
        {
          "source": { "id": "the-verge", "name": "The Verge" },
          "author": "Sean Hollister",
          "title": "Apple’s new M1 Max MacBook Pro may get a virtual turbo button for High Power Mode - The Verge",
          "description": "Steve Moser has discovered a new “High Power Mode” in the macOS Monterey beta that can let the 16-inch MacBook Pro with M1 Max have sustained performance.",
          "url": "https://www.theverge.com/2021/10/21/22739592/apple-m1-max-macbook-pro-high-power-mode-macos-monterey-code",
          "urlToImage": "https://cdn.vox-cdn.com/thumbor/pf-YBO7_88bAm4tp1SP8dIuMGdI=/0x39:1908x1038/fit-in/1200x630/cdn.vox-cdn.com/uploads/chorus_asset/file/22936602/lcimg_2d0f1c7f_4824_4e90_b501_7d917bcd4ae2.jpg",
          "publishedAt": "2021-10-22T00:50:20Z",
          "content": "It was spotted in the code\r\nApple wants you to think of its new M1 Max MacBook Pro as a beast of a machine it actually calls it a beast in the ad but it seems the upcoming macOS Monterey, which ships… [+1687 chars]"
        },
        {
          "source": { "id": null, "name": "CNET" },
          "author": "Eric Mack",
          "title": "Failed Russian satellite crashes back to Earth over the US and Canada - CNET",
          "description": "The spacecraft didn't make it to orbit and went out in a blaze of glory instead.",
          "url": "https://www.cnet.com/news/failed-russian-satellite-crashes-back-to-earth-over-the-us-and-canada/",
          "urlToImage": "https://www.cnet.com/a/img/gfOKkBp62F5BD_7oSVp0ysD-Sow=/1200x630/2021/03/01/380121b9-3f65-4e4d-8b42-4f7f799b3fb9/ukmonfireball.jpg",
          "publishedAt": "2021-10-22T00:30:03Z",
          "content": "A fireball that blazed a slow trail across the skies above Michigan and other nearby states and provinces Tuesday night wasn't a meteor but instead a spacecraft making an unexpected homecoming.  \r\nDo… [+1358 chars]"
        }
      ]
    constructor(){
        super();
        console.log("Hello i am a constructer from news component");
        this.state = {
            articles: this.articles,
            loading: false  
        }
    }

    // async componentDidMount(){
    //   console.log("cdn")
    //   let url = "https://newsapi.org/v2/top-headlines?country=in&apiKey=2c7e73a38f064c34a55b46061e6f20c6"
    //   let data = await fetch(url);
    //   let parsedData = await data.json()
    //   console.log(parsedData);
      
    // }

    render() {
      console.log("render")
        return (
            <div>
                <div className="container my-3">
                    <h2>NewsMonkey - Top headlines </h2>
                    <div className="row">
                    {this.state.articles.map((element)=>{
                        return <div className="col-md-4" key={element.url}>
                            <NewsItem title={element.title.slice(0, 44)} description={element.description.slice(0, 88)} newsUrl={element.url} imageUrl={element.urlToImage} />
                        </div>
                    })}
                    </div>
                </div>
            </div>
        )
    }
}

export default News
